import { ActionCreator, AnyAction } from 'redux';
import { getFromStorage, setToStorage } from 'utils/helpers';
import {
  GET_CURRENT_QUESTION_REQUEST,
  SUBMIT_QUESTION_REQUEST,
  SET_MEASURE_SYSTEM,
  GET_PREV_QUESTION_REQUEST,
  SET_GET_STARTED_QUESTION,
  REGISTER_TO_QUESTIONNAIRE_REQUEST,
  SUBMIT_FEEDBACK_REQUEST,
  SET_GET_STARTED_PRIVACY,
  SET_GET_STARTED_ACCOUNT_INFO,
  GET_FEEDBACK_REQUEST,
  INVALIDATE_COMPLETION_REQUEST,
  PRE_SIGN_UP_REQUEST,
  SET_GET_STARTED_MARKETING_AND_STATE_INFO,
  SET_GET_STARTED_OAUTH_PROVIDER,
  OAUTH_PRE_SIGN_UP_REQUEST,
  SET_GET_STARTED_SIGNUP_TYPE,
  CLINIC_SIGN_UP_GET_CLINICIANS_REQUEST,
  SET_CLINIC_GET_STARTED_APPOINTMENT_DETAILS,
  CLINIC_REGISTER_TO_QUESTIONNAIRE_REQUEST,
  SET_CLINIC_GET_STARTED_CLINIC_CONFIG,
} from '../constants';
import { OAuthProvider } from 'modules/Auth/constants/socialAuth';
import { AnswerPayload, QuestionnaireActionTypes } from '../types';
import { AppThunk } from 'reduxStore';
import { SignupType } from '../constants/signupType';
import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';

export * from './dealbreaker';

export const getCurrentQuestionRequest: ActionCreator<
  QuestionnaireActionTypes
> = (questionId) => ({
  type: GET_CURRENT_QUESTION_REQUEST,
  payload: { questionId },
});

export const getPrevQuestionRequest: ActionCreator<QuestionnaireActionTypes> = (
  questionId,
) => ({
  type: GET_PREV_QUESTION_REQUEST,
  payload: { questionId },
});

export const submitQuestionRequest: ActionCreator<QuestionnaireActionTypes> = ({
  questionId,
  questionTitle,
  currentId,
  answers,
  factsSlugs,
  facts,
  questionType,
}: AnswerPayload) => ({
  type: SUBMIT_QUESTION_REQUEST,
  payload: {
    questionId,
    questionTitle,
    currentId,
    answers,
    factsSlugs,
    facts,
    questionType,
  },
});

export const setMeasureSystem: ActionCreator<QuestionnaireActionTypes> = (
  system: string,
) => ({
  type: SET_MEASURE_SYSTEM,
  payload: { system },
});

export const setGetStartedAccountInfo: ActionCreator<
  QuestionnaireActionTypes
> = ({ email, firstName, lastName, nhsNumber }) => ({
  type: SET_GET_STARTED_ACCOUNT_INFO,
  payload: { email, firstName, lastName, nhsNumber },
});

export const setGetStartedMarketingAndStateInfo: ActionCreator<
  QuestionnaireActionTypes
> = ({ country, state, phone, marketingOptions }) => ({
  type: SET_GET_STARTED_MARKETING_AND_STATE_INFO,
  payload: {
    country,
    state,
    phone,
    marketingOptions,
  },
});

export const setGetStartedQuestion: ActionCreator<QuestionnaireActionTypes> = (
  question: number,
) => ({
  type: SET_GET_STARTED_QUESTION,
  payload: { question },
});

export const setGetStartedPrivacy: ActionCreator<QuestionnaireActionTypes> = (
  options: string[],
) => ({
  type: SET_GET_STARTED_PRIVACY,
  payload: {
    options,
  },
});

export const setGetStartedOAuthProvider: ActionCreator<
  QuestionnaireActionTypes
> = (oAuthProvider: OAuthProvider) => ({
  type: SET_GET_STARTED_OAUTH_PROVIDER,
  payload: { oAuthProvider },
});

export const setGetStartedSignupType: ActionCreator<AnyAction> = (
  signupType: SignupType,
) => ({
  type: SET_GET_STARTED_SIGNUP_TYPE,
  payload: { signupType },
});

export const setClinicGetStartedClinicConfig: ActionCreator<AnyAction> = (
  clinicConfig: ClinicAuthConfig,
) => ({
  type: SET_CLINIC_GET_STARTED_CLINIC_CONFIG,
  payload: { clinicConfig },
});

export const setClinicGetStartedAppointment: ActionCreator<AnyAction> = (
  clinicianId: number,
  appointmentDate: string,
  slug: string | null,
) => ({
  type: SET_CLINIC_GET_STARTED_APPOINTMENT_DETAILS,
  payload: { clinicianId, appointmentDate, slug },
});

export const clinicSignupGetCliniciainsRequest: ActionCreator<AnyAction> = (
  clinicSlug: string,
) => ({
  type: CLINIC_SIGN_UP_GET_CLINICIANS_REQUEST,
  payload: { clinicSlug },
});

export const preSignUpRequest: ActionCreator<QuestionnaireActionTypes> = (
  preSignUpData,
) => ({
  type: PRE_SIGN_UP_REQUEST,
  payload: preSignUpData,
});

export const oAuthPreSignUpRequest: ActionCreator<QuestionnaireActionTypes> = (
  authCode,
  oAuthProvider,
  registerPayload,
) => ({
  type: OAUTH_PRE_SIGN_UP_REQUEST,
  payload: { authCode, oAuthProvider, registerPayload },
});

export const registerToQuestionnaireRequest: ActionCreator<
  QuestionnaireActionTypes
> = ({
  firstName,
  lastName,
  email,
  country,
  state,
  phone,
  password,
  rePassword,
  privacyOptions,
  marketingOptions,
  signupType,
}) => ({
  type: REGISTER_TO_QUESTIONNAIRE_REQUEST,
  payload: {
    firstName,
    lastName,
    email,
    country,
    state,
    phone,
    password,
    rePassword,
    privacyOptions,
    marketingOptions,
    signupType,
  },
});

export const clinicRegisterToQuestionnaireRequest: ActionCreator<
  QuestionnaireActionTypes
> = ({
  firstName,
  lastName,
  email,
  country,
  state,
  phone,
  password,
  rePassword,
  privacyOptions,
  marketingOptions,
  clinicianId,
  appointmentDate,
  slug,
  nhsNumber,
}) => ({
  type: CLINIC_REGISTER_TO_QUESTIONNAIRE_REQUEST,
  payload: {
    firstName,
    lastName,
    email,
    country,
    state,
    phone,
    password,
    rePassword,
    privacyOptions,
    marketingOptions,
    clinicianId,
    appointmentDate,
    slug,
    nhsNumber,
  },
});

export const getFeedbackRequest: ActionCreator<AnyAction> = () => ({
  type: GET_FEEDBACK_REQUEST,
});

export const submitFeedbackRequest: ActionCreator<QuestionnaireActionTypes> = (
  feedback: string,
) => ({
  type: SUBMIT_FEEDBACK_REQUEST,
  payload: {
    feedback,
  },
});

export const getMeasureSystemFromStorage =
  (defaultSystem: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const system = await getFromStorage('measureSystem');
    if (system) {
      dispatch(setMeasureSystem(system));
    } else {
      dispatch(setMeasureSystem(defaultSystem));
    }
  };

export const setMeasureSystemToStorage =
  (system: string): AppThunk =>
  async (): Promise<void> => {
    await setToStorage('measureSystem', system);
  };

export const invalidateCompletion: ActionCreator<AnyAction> = () => ({
  type: INVALIDATE_COMPLETION_REQUEST,
});
