import { getDefaultCountry, phoneAsYouType } from './countries';

export const stringErrorMessages: Record<string, string> = {
  REQUIRED: 'required.',
  INVALID_EMAIL: 'invalid email address.',
  SHORT_PASSWORD: 'password should be at least 8 characters.',
  PASSWORD_MISMATCH: 'passwords should match',
  PASSWORD_LETTERS_NUMBERS: 'password should contain both letters and numbers',
  FEB_WITHOUT_YEAR: 'selected month contains no more than 29 days.',
  MONTH_29_DAYS: 'selected month contains only 29 days.',
  MONTH_28_DAYS: 'selected month contains only 28 days.',
  MONTH_30_DAYS: 'selected month contains only 30 days.',
  MAX_MONTH_DAYS: "month can't contain more than 31 days",
  MAX_MONTH: 'Year contains only 12 months',
  AGE: 'You must be aged between 13 and 55 to use Tuune.\nClick “help” for more info.',
  MIN_YEAR: 'you should select a date after 1950', // 1950
  MAX_YEAR: "you can't select a future date", // current year
  PAST_DATE: "You can't select a past date",
};

export const getErrorMessagesFunctions: Record<
  string,
  (...args: string[]) => string
> = {
  MIN: (field: string, min: string): string =>
    `min value for ${field} is ${min}`,
  MAX: (field: string, max: string): string =>
    `max value for ${field} is ${max}`,
  INVALID: (field: string): string => `invalid value for ${field}`, // Invalid value for month (e.g. month = -1)
};

export const isNotEmpty = (
  field: string,
  value: string | boolean | undefined,
): string | undefined => {
  if (!value) {
    return `${field} ${stringErrorMessages.REQUIRED}`;
  }
};

export const validatePassword = (password?: string): string | undefined => {
  const emptyError = isNotEmpty('Password', password);
  if (emptyError) {
    return emptyError;
  } else if (password && password.length < 8) {
    return stringErrorMessages.SHORT_PASSWORD;
  } else if (
    !/[0-9]/.test(password as string) ||
    !/[a-zA-Z]/.test(password as string)
  ) {
    return stringErrorMessages.PASSWORD_LETTERS_NUMBERS;
  }
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string,
): string | undefined => {
  if (password) {
    const emptyError = isNotEmpty('Confirm password', confirmPassword);
    if (emptyError) {
      return emptyError;
    } else if (password !== confirmPassword) {
      return stringErrorMessages.PASSWORD_MISMATCH;
    }
  }
};

export const validateDaysInDate = (
  day = '',
  month = '',
  year = '',
): string | undefined => {
  const d = parseInt(day);
  const m = parseInt(month);
  const y = parseInt(year);
  if (d < 1) {
    return getErrorMessagesFunctions.INVALID('day');
  }
  if (m === 2 && !y && d) {
    if (d > 29) {
      return stringErrorMessages.FEB_WITHOUT_YEAR;
    }
  } else if (m === 2 && y && d && y.toString().length === 4) {
    if (y % 4 === 0 && d > 29) {
      return stringErrorMessages.MONTH_29_DAYS;
    } else if (y % 4 !== 0 && d > 28) {
      return stringErrorMessages.MONTH_28_DAYS;
    }
  } else if ((m === 4 || m === 6 || m === 9 || m === 11) && d > 30) {
    return stringErrorMessages.MONTH_30_DAYS;
  } else if (d > 31) {
    return stringErrorMessages.MAX_MONTH_DAYS;
  }

  return isNotEmpty('day', day);
};

export const validateMonthInDate = (month = ''): string | undefined => {
  const m = parseInt(month);
  if (m < 1) {
    return getErrorMessagesFunctions.INVALID('month');
  }
  if (m > 12) {
    return stringErrorMessages.MAX_MONTH;
  }

  return isNotEmpty('month', month);
};

export const validateYearInDate = (year = ''): string | undefined => {
  const y = parseInt(year);
  if (y < 1950) {
    return stringErrorMessages.MIN_YEAR;
  }

  if (y > new Date().getFullYear()) {
    return stringErrorMessages.MAX_YEAR;
  }

  return isNotEmpty('year', year);
};

export const validateIsPastDate = (
  day = '',
  month = '',
  year = '',
): string | undefined => {
  const d = parseInt(day);
  const m = parseInt(month);
  const y = parseInt(year);

  if (isNaN(y) && isNaN(m)) return undefined;

  if (new Date().getFullYear() === y) {
    if (new Date().getMonth() + 1 < m) {
      return stringErrorMessages.MAX_YEAR;
    }

    if (!d) return;

    if (new Date().getMonth() + 1 === m && new Date().getDate() < d) {
      return stringErrorMessages.MAX_YEAR;
    }
  }

  return undefined;
};

export const validateIsFutureDate = (
  day = '',
  month = '',
  year = '',
): string | undefined => {
  const d = parseInt(day);
  const m = parseInt(month);
  const y = parseInt(year);

  if (isNaN(y) && isNaN(m) && isNaN(d)) return undefined;

  // Make sure the date string is a valid ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)
  const dateToValidate = new Date(
    `${y}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T23:59:59`,
  );
  const today = new Date();

  if (dateToValidate < today) {
    return stringErrorMessages.PAST_DATE;
  }

  return undefined;
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const phoneCode = getDefaultCountry().phoneCode;

  phoneAsYouType.reset();
  phoneAsYouType.input(`${phoneCode}${phoneNumber}`);

  return phoneAsYouType.isValid();
};
