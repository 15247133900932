import React, { useState } from 'react';
import { StatusBanner } from 'shared';
import { BODY2, H3 } from '@tuunetech/tuune-components';
import { useSelector } from 'react-redux';
import { getFirstName } from 'modules/Account/selectors';
import { HowRecommendationsMadeToggle } from 'shared/ClinicianLauraBio/HowRecommendationMadeToggle';

export const ClinicIntro: React.FC = () => {
  const firstName = useSelector(getFirstName);

  const [isOpenRecInfo, setIsOpenRecInfo] = useState(false);

  return (
    <StatusBanner
      content={
        <>
          <H3 $marginBottom={8}>Hi {firstName},</H3>
          <BODY2>
            Your personalised birth control recommendations are listed below.
          </BODY2>
          <HowRecommendationsMadeToggle
            isOpen={isOpenRecInfo}
            onToggle={() => setIsOpenRecInfo(!isOpenRecInfo)}
          />
        </>
      }
    />
  );
};
