import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import {
  clinicSignupGetCliniciainsRequest,
  setClinicGetStartedClinicConfig,
} from 'modules/Questionnaire/actions';
import GetStartedPage from 'modules/Questionnaire/pages/GetStartedPage';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Page } from 'shared';

import { ClinicPasswordModalContainer } from './ClinicPasswordModalContainer';

export type ClinicLandingProps = {
  clinicAuthConfig: ClinicAuthConfig;
};

export const ClinicLandingContainer: React.FC<ClinicLandingProps> = ({
  clinicAuthConfig,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isPasswordSubmitted, setIsPasswordSubmitted] = useState(false);

  useEffect(() => {
    dispatch(setClinicGetStartedClinicConfig(clinicAuthConfig));
    dispatch(clinicSignupGetCliniciainsRequest(clinicAuthConfig.slug));

    setIsPasswordSubmitted(true);
  }, [history, dispatch, clinicAuthConfig]);

  return isPasswordSubmitted ? (
    <GetStartedPage />
  ) : (
    <Page>
      <ClinicPasswordModalContainer
        onValidPasswordCallback={() => setIsPasswordSubmitted(true)}
      />
    </Page>
  );
};
