import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import Routes from 'routes';
import {
  GridContainer,
  GridItem,
  Button,
  H4,
  BODY1,
  Container,
  //  BODY2,
} from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import {
  PrescriptionConfirmationContainer,
  ListItem,
  ListItemIndicator,
} from './styles';

export type PharmacyConfirmationProps = {
  address?: string;
};

export const PrescriptionConfirmation: React.FC<PharmacyConfirmationProps> = (
  {
    //  address,
  },
) => {
  const [pharmacyImg] = useWebpImage('pharmacy.jpg');

  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PrescriptionConfirmationContainer>
      <GridContainer justify="center">
        <GridItem xs={12} md={10} lg={6}>
          <img src={pharmacyImg} alt="pharmacy image" />
          <H4 $marginTop={isMobile ? 16 : 40}>Thank you!</H4>
          <H4 $marginBottom={40} $marginTop={24}>
            What’s next?
          </H4>
          <ListItem
            startAdornment={<ListItemIndicator>1</ListItemIndicator>}
            content={
              <BODY1 color="textSecondahry">
                You&apos;ll receive a phone call from your Weldricks pharmacists
                in the next 24-48 hours. Calls will be made during working
                hours, Monday to Friday, 9 AM - 5 PM.
              </BODY1>
            }
          />
          <ListItem
            startAdornment={<ListItemIndicator>2</ListItemIndicator>}
            content={
              <Container disableGutters>
                <BODY1 $marginBottom={12}>
                  If you didn’t have your blood measurement, please obtain it
                  before your call. You can get this measured at your nearest
                  pharmacy or at home with a BP monitor.
                </BODY1>
              </Container>
            }
          />
          <ListItem
            startAdornment={<ListItemIndicator>3</ListItemIndicator>}
            content={
              <BODY1>
                Your medication will be dispatched after your call with your
                pharmacist.
              </BODY1>
            }
          />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => history.replace(Routes.RESULTS)}
          >
            Back to report
          </Button>
        </GridItem>
      </GridContainer>
    </PrescriptionConfirmationContainer>
  );
};
