import { ApiHost } from '../axios';
import {
  ACTIVATE_EMAIL_REQUEST,
  GET_USER_ACCESS_TOKEN_REQUEST,
  LOG_OUT_REQUEST,
  LOGIN_REQUEST,
  RESEND_ACTIVATION_REQUEST,
  RESET_PASSWORD_CONFIRM_REQUEST,
  RESET_PASSWORD_REQUEST,
  OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST,
  OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST,
} from 'modules/Auth/constants';
import {
  LoginPayload,
  ResetPasswordPayload,
  ResetPasswordConfirmPayload,
  ActivateEmailPayload,
  AuthRequestActionTypes,
  OAuthLoginWithAuthCodePayload,
  OAuthSignupWithAuthCodePayload,
  GetCliniciansPayload,
} from 'modules/Auth/types';
import {
  CLINIC_SIGN_UP_GET_CLINICIANS_REQUEST,
  MARKETING,
  OAUTH_PRE_SIGN_UP_REQUEST,
  PRE_SIGN_UP_REQUEST,
  CLINIC_REGISTER_TO_QUESTIONNAIRE_REQUEST,
  REGISTER_TO_QUESTIONNAIRE_REQUEST,
} from 'modules/Questionnaire/constants';
import {
  ClinicRegisterPayload,
  EmailRegisterPayload,
} from 'modules/Questionnaire/types';
import { ApiReturnFunc } from './index';
import { registerPayloadToAPIPayload } from './helpers';

const authApi: Record<AuthRequestActionTypes, ApiReturnFunc> = {
  [LOGIN_REQUEST]: ({ email, password }: LoginPayload) => ({
    url: `${ApiHost}/api/v1/auth/jwt/create/`,
    method: 'post',
    data: {
      email,
      password,
    },
    headers: {},
  }),
  [OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST]: ({
    authCode,
    oAuthProvider,
  }: OAuthLoginWithAuthCodePayload) => ({
    url: `${ApiHost}/api/v1/auth/jwt/oauth_create/`,
    method: 'post',
    data: {
      web_client_code: authCode,
      oauth_provider: oAuthProvider,
    },
    headers: {},
  }),
  [OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST]: ({
    authCode,
    oAuthProvider,
    registerPayload,
  }: OAuthSignupWithAuthCodePayload) => ({
    url: `${ApiHost}/api/v1/auth/jwt/oauth_register/`,
    method: 'post',
    data: {
      web_client_code: authCode,
      oauth_provider: oAuthProvider,
      ...registerPayload,
    },
    headers: {},
  }),
  [GET_USER_ACCESS_TOKEN_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/auth/jwt/refresh/`,
      method: 'post',
      headers: {},
    };
  },
  [LOG_OUT_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/auth/jwt/logout/`,
      method: 'get',
      headers: {},
    };
  },
  [RESET_PASSWORD_REQUEST]: ({ email }: ResetPasswordPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/reset_password/`,
      method: 'post',
      data: {
        email,
      },
      headers: {},
    };
  },
  [RESEND_ACTIVATION_REQUEST]: ({ email }: ResetPasswordPayload) => {
    return {
      url: `${ApiHost}/api/v1/user/resend_activation/`,
      method: 'post',
      data: {
        email,
      },
    };
  },
  [RESET_PASSWORD_CONFIRM_REQUEST]: ({
    uid,
    token,
    password,
    confirmPassword,
  }: ResetPasswordConfirmPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/reset_password_confirm/`,
      method: 'post',
      data: {
        uid,
        token,
        new_password: password,
        re_new_password: confirmPassword,
      },
    };
  },
  [PRE_SIGN_UP_REQUEST]: ({
    firstName,
    lastName,
    email,
    country,
    state,
    marketingOptions,
  }: Partial<EmailRegisterPayload>) => {
    return {
      url: `${ApiHost}/api/v1/user/user_pre_signup/`,
      method: 'post',
      data: {
        email,
        first_name: firstName,
        last_name: lastName,
        ...(country && { country }),
        ...(state && { state }),
        [MARKETING.marketingPreference]: marketingOptions?.has(
          MARKETING.marketingPreference,
        ),
        [MARKETING.researchPreference]: marketingOptions?.has(
          MARKETING.researchPreference,
        ),
      },
      headers: {},
    };
  },
  [OAUTH_PRE_SIGN_UP_REQUEST]: ({
    authCode,
    oAuthProvider,
    registerPayload,
  }: Partial<OAuthSignupWithAuthCodePayload>) => {
    return {
      url: `${ApiHost}/api/v1/user/oauth_user_pre_signup/`,
      method: 'post',
      data: {
        web_client_code: authCode,
        oauth_provider: oAuthProvider,
        ...registerPayload,
      },
      headers: {},
    };
  },
  [REGISTER_TO_QUESTIONNAIRE_REQUEST]: (
    registerPayload: EmailRegisterPayload,
  ) => {
    const { firstName, lastName, email, password, rePassword } =
      registerPayload;

    return {
      url: `${ApiHost}/api/v1/auth/jwt/register/`,
      method: 'post',
      data: {
        email,
        first_name: firstName,
        last_name: lastName,
        password: password,
        re_password: rePassword,
        ...registerPayloadToAPIPayload(registerPayload),
      },
      headers: {},
    };
  },
  [CLINIC_REGISTER_TO_QUESTIONNAIRE_REQUEST]: (
    registerPayload: ClinicRegisterPayload,
  ) => {
    const {
      firstName,
      lastName,
      email,
      country,
      state,
      password,
      rePassword,
      clinicianId,
      appointmentDate,
      slug,
      nhsNumber,
    } = registerPayload;

    const _nhsNumber = nhsNumber ? nhsNumber : 'N/A';
    return {
      url: `${ApiHost}/api/v1/auth/jwt/clinic_user_register/`,
      method: 'post',
      data: {
        email,
        country,
        state,
        first_name: firstName,
        last_name: lastName,
        password: password,
        re_password: rePassword,
        clinician: clinicianId,
        appointment_date: appointmentDate,
        slug: slug,
        nhs_number: _nhsNumber,
        ...registerPayloadToAPIPayload(registerPayload),
      },
      headers: {},
    };
  },
  [ACTIVATE_EMAIL_REQUEST]: ({ uid, token }: ActivateEmailPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/activation/`,
      method: 'post',
      data: {
        uid,
        token,
      },
    };
  },

  [CLINIC_SIGN_UP_GET_CLINICIANS_REQUEST]: ({
    clinicSlug,
  }: GetCliniciansPayload) => {
    return {
      url: `${ApiHost}/api/v1/clinics/${clinicSlug}/clinicians/`,
      method: 'get',
    };
  },
};

export default authApi;
