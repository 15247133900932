import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { Form, HAControlButtons } from 'shared';
import { DateAnswerFormValues } from 'modules/Questionnaire/types';
import { DateEditableFields } from 'modules/Questionnaire/helpers';
import DateInputs from './DateInputs';
import { Box } from '@material-ui/core';
import { BODY2 } from '@tuunetech/tuune-components';

interface DateAnswerProps {
  initialValues: DateAnswerFormValues;
  validate: (values: DateAnswerFormValues) => void;
  handleSubmit: (values: DateAnswerFormValues) => void;
  handleRequestNext: () => void;
  editableFields: DateEditableFields;
}

const DateAnswer: React.FC<DateAnswerProps> = ({
  initialValues,
  validate,
  handleSubmit,
  handleRequestNext,
  editableFields,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({
        values,
        errors,
        touched,
        isValid,
        dirty,
        submitForm,
        handleChange,
        handleBlur,
        setFieldTouched,
      }): ReactNode => {
        const submit = (): void | Promise<void> => {
          if (isValid) {
            return dirty ? submitForm() : handleRequestNext();
          } else {
            Object.keys(values).forEach((key) => setFieldTouched(key));
          }
        };

        return (
          <>
            <Form disableSubmit={!isValid}>
              <Box display="flex" gridColumnGap="8px">
                {editableFields.day && (
                  <DateInputs
                    fieldName={'day'}
                    placeHolder={'dd'}
                    dateProps={{ min: 1, max: 31 }}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}
                {editableFields.month && (
                  <DateInputs
                    fieldName={'month'}
                    placeHolder={'mm'}
                    dateProps={{ min: 1, max: 12 }}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}
                {editableFields.year && (
                  <DateInputs
                    fieldName={'year'}
                    placeHolder={'yyyy'}
                    dateProps={{
                      min: 1950,
                      max: new Date().getFullYear(),
                    }}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}
              </Box>
              <Box
                display={
                  Object.keys(errors).length !== 0 &&
                  Object.keys(touched).length !== 0
                    ? 'flex'
                    : 'none'
                }
                flexDirection="column"
                marginTop="8px"
              >
                {editableFields.day && !!(touched.day && errors.day) && (
                  <BODY2 color="error">{errors.day}</BODY2>
                )}
                {editableFields.month && !!(touched.month && errors.month) && (
                  <BODY2 color="error">{errors.month}</BODY2>
                )}
                {editableFields.year && !!(touched.year && errors.year) && (
                  <BODY2 color="error">{errors.year}</BODY2>
                )}
              </Box>
            </Form>
            <HAControlButtons onClick={submit} />
          </>
        );
      }}
    </Formik>
  );
};

export default DateAnswer;
