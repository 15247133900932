import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
  ClinicsConfigContext,
  ClinicsContextObj,
  CLINICS_CONTEXT_DEFAULT_VALUE,
} from './context';

const CLINICS_CONFIG_URL = '/configs/clinics.json';

export const ClinicsConfigProvider: React.FC = ({ children }) => {
  const [clinicsConfig, setClinicsConfig] = useState<ClinicsContextObj>(
    CLINICS_CONTEXT_DEFAULT_VALUE,
  );

  useEffect(() => {
    setClinicsConfig({ isLoading: true, clinicsConfig: {} });
    axios.get(CLINICS_CONFIG_URL).then(
      (response) => {
        setClinicsConfig({
          isLoading: false,
          clinicsConfig: response.data,
        });
      },
      (error) => {
        // TODO sentry error
        console.log(error);
        setClinicsConfig({
          isLoading: false,
          clinicsConfig: {},
        });
      },
    );
  }, []);

  return (
    <ClinicsConfigContext.Provider value={clinicsConfig}>
      {children}
    </ClinicsConfigContext.Provider>
  );
};
