export enum PRIVACY {
  acceptAnswersTruthful = 'accept_answers_truthful',
  acceptDataUsage = 'accept_data_usage',
  acceptTerms = 'accept_terms',
  acceptAssignedAtBirth = 'accept_assigned_at_birth',
  acceptTestingAgreement = 'accept_testing_agreement',
  acceptWeldricksTerms = 'accept_weldricks_terms',
  consentSummaryCardRecord = 'consent_summary_card_record',
  consentGpPractice = 'consent_gp_practice',
  consentWeldricksShare = 'consent_weldricks_share',
}
