import React, {
  useCallback,
  useState,
  ReactNode,
  ReactElement,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  RecommendationTable,
  RecommendationTableProps,
} from '@tuunetech/tuune-components';
import { useAnalytics } from 'utils/analytics';
import {
  ANALYTICS_CATEGORY,
  ANALYTICS_EVENT,
  REPORT_STAGE_STATUS_MAPPING,
} from 'utils/analytics/constants';
import { getClinicInfo, getReportStageStatus } from 'modules/Account/selectors';
import {
  ContraceptiveEligibility,
  ReferralRecommendation,
} from 'modules/Results/types';
import { HowRecommendationsMadeContainer } from '../../containers';
import RecommendationDetails from '../RecommendationDetails';
import { getSpecialFlags } from 'modules/Results/selectors';
import {
  contraceptivesToAnalyticsObj,
  issuesToAnalyticsObj,
  specialFlagsToAnalyticsObj,
} from 'utils/analytics/helpers';
import { CONTRACEPTIVE_TYPES_FOR_PRESCRIPTION } from 'modules/Results/constants';

type ReportRecommendation =
  RecommendationTableProps['recommendations'][number] & {
    slug: string;
    id: number;
    eligibility: ContraceptiveEligibility;
    informationRequired: string[];
    isCurrent?: boolean;
  };

export type RecommendationLayoutProps = {
  recommendations?: ReportRecommendation[];
  benefitList: { name: string; slug: string; icon: ReactElement }[];
  isRecommendationDetailsDialogOpen: boolean;
  onRecommendationDetailsDialogClose: () => void;
  currentRecommendation?: ReportRecommendation;
  referralRecommendation: ReferralRecommendation;
  considerationsSummaryContent: Record<string, ReactNode>;
  pillRegimenSummaryContent: Record<string, ReactNode>;
  switchingPillInfoSummary: ReactNode;
};

const RecommendationLayout: React.FC<RecommendationLayoutProps> = ({
  recommendations,
  benefitList,
  currentRecommendation,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  referralRecommendation,
  isRecommendationDetailsDialogOpen,
  onRecommendationDetailsDialogClose,
  considerationsSummaryContent,
  pillRegimenSummaryContent,
  switchingPillInfoSummary,
}) => {
  const analytics = useAnalytics();
  const reportStageStatus = useSelector(getReportStageStatus);
  const specialFlags = useSelector(getSpecialFlags);
  const clinicInfo = useSelector(getClinicInfo);

  useEffect(() => {
    if (reportStageStatus && !!recommendations) {
      analytics.track(ANALYTICS_EVENT.VIEW_REPORT, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        reportStageStatus: REPORT_STAGE_STATUS_MAPPING[reportStageStatus],
        ...contraceptivesToAnalyticsObj(recommendations),
        ...issuesToAnalyticsObj(benefitList),
        ...specialFlagsToAnalyticsObj(specialFlags),
      });
    }
  }, [
    analytics,
    reportStageStatus,
    specialFlags,
    benefitList,
    recommendations,
  ]);

  const handleHowRecommendationsMadeOpen = useCallback(() => {
    setIsHowRecommendationsMadeOpen(true);
    analytics.track(ANALYTICS_EVENT.RECOMMENDATION_HOW_WAS_IT_MADE, {
      category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
    });
  }, [analytics]);

  const [isHowRecommendationsMadeOpen, setIsHowRecommendationsMadeOpen] =
    useState(false);

  const handleHowRecommendationsMadeClose = useCallback(() => {
    setIsHowRecommendationsMadeOpen(false);
  }, []);

  const onBenefitDropdownCallback = useCallback(
    (
      recommendationName: string,
      benefitDetails?: { benefit: string; impact: string },
    ) => {
      benefitDetails &&
        analytics.track(ANALYTICS_EVENT.RECOMMENDATION_BENEFITS_DROPDOWN, {
          category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
          ...benefitDetails,
          recommendationName,
        });
    },
    [analytics],
  );

  const onRecommendationsScrollCallback = useCallback(() => {
    analytics.track(
      ANALYTICS_EVENT.RECOMMENDATION_REPORT_SCROLLED_RECOMMENDATIONS_CARDS,
      {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      },
    );
  }, [analytics]);

  const formattedRecommendations = recommendations?.map((recommendation) => {
    return {
      ...recommendation,
      type: recommendation.isCurrent ? 'Current method' : recommendation.type,
      // For clinic flow default to 'more info'
      ctaText: clinicInfo
        ? 'Choose recommendation'
        : CONTRACEPTIVE_TYPES_FOR_PRESCRIPTION.includes(recommendation.type) &&
          recommendation.eligibility !== 'no'
        ? 'Select for pharmacist to review'
        : 'Select for pharmacist to review',
      onBenefitDropdownClick: (benefitDetails?: {
        benefit: string;
        impact: string;
      }) => onBenefitDropdownCallback(recommendation.name, benefitDetails),
    };
  });

  const getCardItemId = (currentRecommendation: ReportRecommendation) => {
    // if (currentRecommendation.status === 'requiresSpecialist') {
    // Allow everyone to get to the order page
    // return referralRecommendation.id;
    // } else {
    return currentRecommendation.id;
    //}
  };

  return (
    <>
      <HowRecommendationsMadeContainer
        isHowRecommendationsMadeOpen={isHowRecommendationsMadeOpen}
        handleHowRecommendationsMadeOpen={handleHowRecommendationsMadeOpen}
        handleHowRecommendationsMadeClose={handleHowRecommendationsMadeClose}
      />
      {currentRecommendation && (
        <RecommendationDetails
          name={currentRecommendation.name}
          ingredients={currentRecommendation.ingredients}
          slug={currentRecommendation.slug}
          id={getCardItemId(currentRecommendation)}
          generics={currentRecommendation.generics}
          considerationSummary={
            considerationsSummaryContent[currentRecommendation.slug]
          }
          pillRegimenSummary={
            pillRegimenSummaryContent[currentRecommendation.slug]
          }
          switchingPillInfoSummary={switchingPillInfoSummary}
          type={currentRecommendation.type}
          isOpen={isRecommendationDetailsDialogOpen}
          onClose={onRecommendationDetailsDialogClose}
          eligibility={currentRecommendation.eligibility}
          informationRequired={currentRecommendation.informationRequired}
        />
      )}
      {formattedRecommendations ? (
        <RecommendationTable
          recommendations={formattedRecommendations}
          benefitList={benefitList}
          prioritizeLinkClickCallback={handleHowRecommendationsMadeOpen}
          onRecommendationsScroll={onRecommendationsScrollCallback}
          addEligibilityRow
        />
      ) : null}
    </>
  );
};

export default RecommendationLayout;
