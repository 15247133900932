import { ContraceptiveEligibilityStatus } from '@tuunetech/tuune-components';
import { ContraceptiveType } from '../constants';
import {
  ContraceptiveEligibility,
  ContraceptiveRecommendation,
} from '../types';

export const getFilteredRecommendations = (
  recommendations: ContraceptiveRecommendation[],
  contraceptiveTypesFilter: ContraceptiveType[],
  returnOnlyAlternatives = false,
): ContraceptiveRecommendation[] => {
  let filteredRecommendations: ContraceptiveRecommendation[] = [];

  if (recommendations.length > 0) {
    // Always keep our recommendation first
    filteredRecommendations[0] = recommendations[0];

    // If present, always keep the current method second
    const currentRecommendation = recommendations.filter(
      (recommendation) => recommendation.current,
    );
    filteredRecommendations = filteredRecommendations.concat(
      currentRecommendation,
    );

    // Filter the rest of the recommendations based on the contracetive types filter
    const filteredByType = recommendations.filter(
      (recommendation) =>
        !filteredRecommendations.includes(recommendation) &&
        contraceptiveTypesFilter.includes(recommendation.recommendationType),
    );

    if (!returnOnlyAlternatives) {
      filteredRecommendations = filteredRecommendations.concat(filteredByType);
    } else {
      filteredRecommendations = filteredByType;
    }
  }
  return filteredRecommendations;
};

export const getContraceptiveStatus = (
  current: boolean | undefined,
  eligible: ContraceptiveEligibility,
  informationRequired: string[],
): ContraceptiveEligibilityStatus => {
  if (current && eligible === 'no') {
    return ContraceptiveEligibilityStatus.Unrecommended;
  } else if (eligible === 'specialist' && !!informationRequired.length) {
    return ContraceptiveEligibilityStatus.RequiresBPCheckAndSpecialist;
  } else if (eligible === 'specialist') {
    return ContraceptiveEligibilityStatus.RequiresSpecialist;
  } else if (!!informationRequired.length) {
    return ContraceptiveEligibilityStatus.RequiresBPCheck;
  } else if (current && eligible === 'yes') {
    return ContraceptiveEligibilityStatus.None;
  }

  return ContraceptiveEligibilityStatus.Best;
};

export const getPrescriptionCtaText = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  eligible: ContraceptiveEligibility,
): string => {
  //if (eligible === 'specialist') {
  //  return `talk to an obgyn • $49`;
  //} else {
  return 'Select for pharmacist to review';
  //}
};

export const getClinicalReviewInfoText = (
  eligible: ContraceptiveEligibility,
  informationRequired: string[],
): string => {
  if (eligible === 'specialist') {
    if (!!informationRequired.length) {
      return 'Requires blood pressure check and clinical review';
    } else {
      return 'Requires clinical review';
    }
  } else {
    return '';
  }
};
