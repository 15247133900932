import React from 'react';
import {
  useMediaQuery,
  useTheme,
  //MenuItem,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Button, H3, BODY1 } from '@tuunetech/tuune-components';
import {
  Control,
  //Controller,
  FieldErrors,
  FieldValues,
} from 'react-hook-form';
import { useWebpImage } from 'utils/image';
//import { US_STATES } from 'utils/usStates';
import { PharmacyContainer, Input } from './styles';
import { OnlinePharmacyFormData, PhysicalPharmacyFormData } from '../../types';
import { ErrorBox } from 'shared';
import { AuthLayoutImg } from 'shared/AuthLayout/styles';

type PharmacyForm<T extends FieldValues> = {
  control: Control<T>;
  handleSubmit: () => void;
  errors: FieldErrors<T>;
  isValid: boolean;
};

export type PharmacyProps = {
  pharmacyType: string;
  onlinePharmacyForm: PharmacyForm<OnlinePharmacyFormData>;
  physicalPharmacyForm: PharmacyForm<PhysicalPharmacyFormData>;
  nonFieldErrorMessage?: string;
  isLoading: boolean;
};
//const O_PHARMACY = 'Online pharmacy';
//const P_PHARMACY = 'Physical pharmacy';

export const Pharmacy: React.FC<PharmacyProps> = ({
  physicalPharmacyForm: physicalPForm,
  nonFieldErrorMessage,
  isLoading,
  //  pharmacyType,
}) => {
  const [PharmacyImg] = useWebpImage('pharmacy.jpg');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PharmacyContainer>
      <Box width="630px" maxWidth="100%" marginX="auto">
        <AuthLayoutImg src={PharmacyImg} />
        <H3 $marginTop={40} $marginBottom={16}>
          Confirm details
        </H3>
        <BODY1 $marginBottom={40}>
          Please provide your delivery details. One of our pharmacists will call
          you with the next 24-48 hours to confirm and prescribe your chosen
          method. Calls will be made during working hours, Monday to Friday, 9
          AM - 5 PM excluding bank holidays.
        </BODY1>
        {!!nonFieldErrorMessage && (
          <Box marginTop="40px" marginBottom="16px">
            <ErrorBox text={nonFieldErrorMessage} />
          </Box>
        )}
        {
          /* pharmacyType === P_PHARMACY && */
          <form onSubmit={physicalPForm.handleSubmit}>
            <Input
              name="pharmacyName"
              placeholder="Full name *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.name?.message}
              error={!!physicalPForm.errors.name}
            />
            <Input
              name="addressLine1"
              placeholder="Address *"
              control={physicalPForm.control}
              fullWidth
              helperText={
                physicalPForm.errors.addressLine1
                  ? physicalPForm.errors.addressLine1?.message
                  : 'Street address'
              }
              error={!!physicalPForm.errors.addressLine1}
            />
            <Input
              name="city"
              placeholder="City *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.city?.message}
              error={!!physicalPForm.errors.city}
            />
            <Input
              name="state"
              placeholder="County *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.state?.message}
              error={!!physicalPForm.errors.state}
            />
            <Input
              name="zipCode"
              placeholder="Post code *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.zipCode?.message}
              error={!!physicalPForm.errors.zipCode}
            />
            <Input
              name="pharmacyPhone"
              placeholder="Contact Number *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.pharmacyPhone?.message}
              error={!!physicalPForm.errors.pharmacyPhone}
            />
          </form>
        }
        <Button
          fullWidth={isMobile}
          onClick={physicalPForm.handleSubmit}
          // disable button logic:
          // if type is online - disable if it's not valid;
          // if type is physical - disable if any of two forms is not valid
          disabled={isLoading || !physicalPForm.isValid}
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          confirm
        </Button>
      </Box>
    </PharmacyContainer>
  );
};
