import React from 'react';
import { AppHeader } from '@tuunetech/tuune-components';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { PharmacyContainer } from '../containers/PharmacyContainer';
import { Page } from 'shared';

export const PharmacyPage: React.FC = () => {
  const headerButtons = useBaseNavButtons();
  return (
    <Page>
      <AppHeader
        pageTitle="Delivery details"
        handleGoBack={headerButtons.goBack}
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />
      <PharmacyContainer />
    </Page>
  );
};
